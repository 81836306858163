/* eslint-disable @typescript-eslint/no-explicit-any */
import { t } from 'i18next';
import React, { FunctionComponent } from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { CBCustomerDataErrorBadge } from '../../components/content_builder_components/CBCustomerDataErrorBadge';
import { CBCustomerInvitationStateBadge } from '../../components/content_builder_components/CBCustomerInvitationStateBadge';
import { CustomerTable } from '../../components/CustomerTable';
import { isCodie } from '../../appBrand';

const infoUrl = isCodie
    ? 'https://hilfe.dashboard.app.fp-finanzpartner.de/sl-SI/kb/articles/wie-kann-ich-meine-kunden-f-r-die-fp-360-app-einladen'
    : undefined;

export const InviteCustomersList: FunctionComponent = () => {
    const columns = [
        {
            Header: t('fullName'),
            id: 'fullName',
            accessor: (row: any) => row['fullName'],
            defaultCanFilter: true,
        },
        {
            Header: t('email'),
            id: 'email',
            accessor: (row: any) => row['email'],
            defaultCanFilter: true,
        },
        {
            Header: t('invitationStateBadge'),
            id: 'invitationState',
            accessor: (row: any) => row['invitationState'],
            Cell: (cell: any) => {
                return (
                    <CBCustomerInvitationStateBadge
                        data={cell.row.original.invitationState}
                        stats={cell.row.original.stats}
                    />
                );
            },
            defaultCanFilter: true,
            disableSortBy: true,
        },
        {
            Header: t('dataErrorBadge'),
            id: 'dataErrorBadge',
            accessor: (row: any) => row['dataErrorBadge'],
            Cell: (cell: any) => {
                return <CBCustomerDataErrorBadge data={cell.row.original.dataErrors} />;
            },
            disableSortBy: true,
        },
        {
            Header: t('customerIdentifier'),
            id: 'customerIdentifier',
            accessor: (row: any) => row['customerIdentifier'],
            defaultCanFilter: true,
            disableSortBy: true,
        },
        {
            Header: t('advisorFullName'),
            id: 'advisorFullName',
            accessor: (row: any) => row['advisorFullName'],
            defaultCanFilter: true,
        },
    ];

    return (
        <ContentContainer headline="inviteCustomersHeadline" description="inviteCustomersDescription" infoUrl={infoUrl}>
            <CustomerTable columns={columns} />
        </ContentContainer>
    );
};
